<template>
  <div class="p-grid case">
    <div class="p-col-fixed title">
      <p>{{ caseNo }}</p>
    </div>
    <div class="p-col-6 p-md-7 p-lg-7 desc">
      <h3>{{ dr }}</h3>
      <p>{{ report }}</p>
    </div>
    <div class="p-col-2 button">
      <slot name="butt"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["caseNo", "dr", "report"],
  setup() {
    return {};
  },
};
</script>

<style scoped>
.case {
  background-color: white;
  margin: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 5px;
}
.case:hover {
  margin: 7px 15px;
}
.title {
  display: inline-block;
  width: 17%;
}
.desc {
  text-align: left;
}
</style>